import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import auth from '@services/auth'

const AuthenticatedRoutes = () => {
  return auth.getUser() ? <Outlet /> : <Navigate to='/login' />
}

export default AuthenticatedRoutes
