import React from 'react'
import { Route, Routes, Navigate } from 'react-router'
import AuthenticatedRoutes from './AuthenticatedRoutes'
import Login from '@pages/Login'
import Logout from '@pages/Logout'
import Dashboard from '@pages/Dashboard'
import MessageHistory from '@pages/MessageHistory'

const AppRoutes = () => (
  <Routes>
    <Route path='/' element={<AuthenticatedRoutes />}>
      <Route index element={<Dashboard />} />
      <Route path='messages' element={<MessageHistory />} />
    </Route>

    <Route path='login' element={<Login />} />
    <Route path='logout' element={<Logout />} />
    <Route path='*' element={<Navigate to='/' />} />
  </Routes>
)

export default AppRoutes
