import React, { useEffect, useRef, useState } from 'react'

import { getRelativeTime } from '@utils/dayjs'

import ScrollableContent from '@components/ScollableContent'
import Message from '@components/Message'

import styles from './styles.module.css'

const Conversation = ({ record }) => {
  const [showMessages, setShowMessages] = useState(false)
  const scrollToRef = useRef()

  const headerHeight = document.getElementById('page-header').clientHeight
  const conversation = record.conversation

  useEffect(() => {
    showMessages &&
      window.scrollTo({ top: scrollToRef.current.offsetTop - (headerHeight + 12), left: 0, behavior: 'smooth' })
  }, [showMessages, headerHeight])

  return (
    <div
      className={styles.conversation}
      data-show-messages={showMessages}
      data-messages={conversation.length}
      ref={scrollToRef}
    >
      <div className={styles.details} onClick={() => !!conversation.length && setShowMessages(!showMessages)}>
        <div className={styles.participants}>
          <div>
            <em>{record.participant}</em>
            <small>({record.participantLocalId})</small>
          </div>
          <div>
            <em>{record.sponsor}</em>
            <small>({record.sponsorGlobalId})</small>
          </div>
        </div>
        <div className={styles.stats}>
          <div>
            <b>{conversation.length}</b>
            <time>{getRelativeTime([...conversation].pop()?.sentAt)}</time>
          </div>
          <span>^</span>
        </div>
      </div>

      <ScrollableContent reverse={true}>
        <ul className={styles.messages}>
          {conversation.map((msg, idx) => (
            <Message key={idx} msg={msg} />
          ))}
        </ul>
      </ScrollableContent>
    </div>
  )
}

export default Conversation
