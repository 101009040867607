import React from 'react'

import styles from './styles.module.css'

const StatDescription = ({ description, stat }) => {
  return (
    <dl className={styles.description}>
      <dd>{stat}</dd>
      <dt>{description}</dt>
    </dl>
  )
}

export default StatDescription
