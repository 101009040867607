import React, { useState } from 'react'

import { useFetch } from '@cvg/react-hooks'

import { getConversations } from '@services/conversations'
import { CACHE_KEYS } from '@utils/swr'

import Page from '@components/Page'
import PageHeader from '@components/PageHeader'
import PageMain from '@components/PageMain'
import PageContent from '@components/PageContent'
import Sort from '@components/Sort'
import Filter from '@components/Filter'
import Conversation from '@components/Conversation'

import styles from './styles.module.css'

const MessageHistory = () => {
  const { data, error, isLoading } = useFetch(getConversations, { cacheKey: CACHE_KEYS.conversations })
  const [sortedData, setSortedData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  return (
    <Page>
      <PageHeader />
      <PageMain>
        <PageContent fallbackText='messages' isError={!!error} isLoading={isLoading} title='Message History'>
          <div className={styles.filters}>
            <Sort data={data} setSortedData={setSortedData} />
            <Filter data={data} onChange={setFilteredData} />
          </div>
          <div className={styles.history}>
            {sortedData
              .filter(item => filteredData.includes(item))
              .map(record => (
                <Conversation key={record.participantLocalId} record={record} />
              ))}
          </div>
        </PageContent>
      </PageMain>
    </Page>
  )
}

export default MessageHistory
