import React, { useEffect, useRef, useState } from 'react'

import styles from './styles.module.css'

const DropDownMenu = ({ children, className }) => {
  const [showMenu, setShowMenu] = useState(false)
  const menuRef = useRef()

  const handleClick = e => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setShowMenu(false)
    }
  }

  useEffect(() => {
    showMenu && document.addEventListener('click', handleClick)
    !showMenu && document.removeEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [showMenu])

  return (
    <div className={`${styles.menu} ${className}`} ref={menuRef}>
      <button aria-label='More' onClick={() => setShowMenu(!showMenu)} />
      <div data-show={showMenu}>{children}</div>
    </div>
  )
}

export default DropDownMenu
