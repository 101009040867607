import React, { useEffect, useState } from 'react'

import { SORT_OPTIONS } from '@utils/constants'

import { Input } from '@cvg/react-components'

const Sort = ({ data, setSortedData }) => {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS.recent)

  const sort = (data, option) => {
    return [...data].sort((a, b) =>
      option === SORT_OPTIONS.recent
        ? b.conversation.reduce((max, { sentAt }) => Math.max(new Date(sentAt).getTime(), max), 0) -
          a.conversation.reduce((max, { sentAt }) => Math.max(new Date(sentAt).getTime(), max), 0)
        : b.conversation.length - a.conversation.length
    )
  }

  useEffect(() => {
    setSortedData(sort(data, SORT_OPTIONS.recent))
  }, [data])

  const onChange = e => {
    setSortOption(e.target.value)
    setSortedData(sort(data, e.target.value))
  }

  return (
    <div>
      <Input name='sort' onChange={onChange} type='select' value={sortOption}>
        {Object.values(SORT_OPTIONS).map(sortOption => (
          <option key={sortOption} value={sortOption}>
            {sortOption}
          </option>
        ))}
      </Input>
    </div>
  )
}

export default Sort
