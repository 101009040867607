import React from 'react'

import styles from './styles.module.css'

const Stats = ({ children, className, header }) => {
  return (
    <section className={`${styles.stats} ${className && className}`}>
      <h3>{header}</h3>
      {children}
    </section>
  )
}

export default Stats
