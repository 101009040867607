import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

const ScrollableContent = ({ children, reverse = false }) => {
  return (
    <div className={styles.scrollable} data-reverse={reverse}>
      {children}
    </div>
  )
}

ScrollableContent.propTypes = {
  children: PropTypes.node.isRequired,
  reverse: PropTypes.bool
}

export default ScrollableContent
