export const MESSAGE_TYPES = Object.freeze({
  mediaUrl: 'mediaUrl',
  message: 'message'
})

export const MODERATED_BY = Object.freeze({
  human: 'Human',
  patch: 'Patch',
  rekognition: 'Rekognition',
  sightengine: 'Sight Engine'
})

export const SEARCHABLE_KEYS = Object.freeze(['participant', 'participantLocalId', 'sponsor', 'sponsorGlobalId'])

export const SORT_OPTIONS = Object.freeze({
  messages: 'Sort - Most Messages',
  recent: 'Sort - Most Recent'
})
