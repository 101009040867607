import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { SEARCHABLE_KEYS } from '@utils/constants'

import { Input } from '@cvg/react-components'

const Filter = ({ data, onChange }) => {
  const [filter, setFilter] = useState('')

  useEffect(() => {
    onChange(
      data.filter(item =>
        SEARCHABLE_KEYS.reduce((values, key) => values + item[key], '')
          .toLowerCase()
          .includes(filter.toLowerCase())
      )
    )
  }, [filter, data])

  return (
    <Input
      aria-label='Search'
      name='filter'
      onChange={event => setFilter(event.target.value)}
      placeholder='Search'
      type='search'
      value={filter}
    />
  )
}

Filter.propTypes = {
  data: PropTypes.array,
  onChange: PropTypes.func.isRequired
}

export default Filter
