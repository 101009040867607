import { get } from './clients/fetch'

import logger from '@utils/logger'

export const getStats = async id => {
  return await get('/stats?userType=participant/sponsor').catch(error => {
    logger.error('getStats()', { error, id })
    throw error
  })
}
