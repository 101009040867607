import React from 'react'

import Stats from '@components/Stats'
import Stat from '@components/Stat'
import StatDescription from '@components/StatDescription'

import styles from './styles.module.css'

const MessageStats = ({ stats }) => {
  return (
    <Stats className={styles.message} header='Messages'>
      <Stat>
        <StatDescription description='Messages Sent' stat={stats.numMessagesSent} />
      </Stat>
      <Stat>
        <StatDescription description='Images Sent' stat={stats.numPicturesSent} />
      </Stat>
    </Stats>
  )
}

export default MessageStats
