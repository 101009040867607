import React from 'react'
import PropTypes from 'prop-types'

import { MESSAGE_TYPES } from '@utils/constants'

import styles from './styles.module.css'

const MessageContent = ({ msg, type }) => {
  if (!msg[type]) return null

  return (
    <li className={styles.message} data-from={msg.userType}>
      <i>{msg.userType}</i>
      {type === MESSAGE_TYPES.message && <p>{msg.message}</p>}
      {type === MESSAGE_TYPES.mediaUrl && <img src={msg.mediaUrl} />}
    </li>
  )
}

const Message = ({ msg }) => {
  return Object.values(MESSAGE_TYPES).map((type, idx) => <MessageContent key={idx} msg={msg} type={type} />)
}

Message.propTypes = {
  msg: PropTypes.object.isRequired
}

export default Message
