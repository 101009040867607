import React from 'react'

import styles from './styles.module.css'

const StatData = ({ description, stat }) => {
  return (
    <>
      <dd className={styles.desc}>{description}:</dd>
      <dt className={styles.stat}>{stat}</dt>
    </>
  )
}

export default StatData
