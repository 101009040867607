import React from 'react'

import { useFetch } from '@cvg/react-hooks'

import { getStats } from '@services/stats'
import { CACHE_KEYS } from '@utils/swr'

import Page from '@components/Page'
import PageHeader from '@components/PageHeader'
import PageMain from '@components/PageMain'
import PageContent from '@components/PageContent'
import OnboardingStats from '@components/OnboardingStats'
import MessageStats from '@components/MessageStats'

const Dashboard = () => {
  const { data, error, isLoading } = useFetch(getStats, { cacheKey: CACHE_KEYS.stats })

  return (
    <Page>
      <PageHeader />
      <PageMain>
        <PageContent fallbackText='stats' isError={!!error} isLoading={isLoading} title='Dashboard'>
          <OnboardingStats stats={data?.onboarding} />
          <MessageStats stats={data?.messages} />
        </PageContent>
      </PageMain>
    </Page>
  )
}

export default Dashboard
