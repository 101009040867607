import React from 'react'
import { useNavigate } from 'react-router-dom'

import { OtpLogin } from '@cvg/react-otp-auth'

import auth from '@services/auth'

import Page from '@components/Page'
import PageHeader from '@components/PageHeader'

import styles from './styles.module.css'

const Login = () => {
  const navigate = useNavigate()

  return (
    <Page className={styles.login}>
      <PageHeader showSettingsIcon={false} />
      <OtpLogin
        auth={auth}
        className={styles['form-container']}
        redirectCallback={() => navigate('/')}
        theme={{ color: '#005EB8' }}
      />
    </Page>
  )
}

export default Login
