export const CACHE_KEYS = {
  conversations: 'conversations',
  stats: 'stats'
}

export const deleteCache = (cache, cacheKey) => {
  cache.delete(cacheKey)
}

export const getCache = (cache, cacheKey) => {
  return cache.get(cacheKey)
}
