import React from 'react'

import Stats from '@components/Stats'
import Stat from '@components/Stat'
import StatDescription from '@components/StatDescription'
import StatList from '@components/StatList'
import StatData from '@components/StatData'

import styles from './styles.module.css'

const OnboardingStats = ({ stats }) => {
  return (
    <Stats className={styles.onboarding} header='Onboarding'>
      <Stat>
        <StatDescription description='Sponsors Invited' stat={stats.numSponsorsInvited} />
        {stats && (
          <StatList>
            {stats.sponsorsInvitedByGp.map(item => (
              <StatData key={item.globalPartner} description={item.globalPartner} stat={item.numInvited} />
            ))}
          </StatList>
        )}
      </Stat>

      <Stat>
        <StatDescription description='Sponsors Opted In' stat={stats.numOptedInSponsors} />
        {stats && (
          <StatList>
            {stats.optedInSponsorsByGp.map(item => (
              <StatData key={item.globalPartner} description={item.globalPartner} stat={item.numOptedIn} />
            ))}
          </StatList>
        )}
      </Stat>
    </Stats>
  )
}

export default OnboardingStats
