import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import auth from '@services/auth'

import DropDownMenu from '@components/DropDownMenu'
import styles from './styles.module.css'

const PageNav = () => {
  const { pathname } = useLocation()

  if (!auth.getUser()) return null

  return (
    <nav className={styles.nav}>
      <Link data-active={pathname === '/'} to='/'>
        Dashboard
      </Link>

      <Link data-active={pathname === '/messages'} to='/messages'>
        Messages
      </Link>

      <DropDownMenu className={styles.more}>
        <Link to='/logout'>Logout</Link>
      </DropDownMenu>
    </nav>
  )
}

export default PageNav
