import React from 'react'

import { ErrorFallback, LoadingFallback } from '@cvg/react-components'

import styles from './styles.module.css'

const PageContent = ({ children, fallbackText, isError = false, isLoading = false, showSpinner = true, title }) => {
  return (
    <div className={styles.content}>
      {title && <h2>{title}</h2>}
      <ErrorFallback isError={isError} fallback={`Error loading ${fallbackText}`}>
        <LoadingFallback isLoading={isLoading} showSpinner={showSpinner} fallback={`Loading ${fallbackText}`}>
          {children}
        </LoadingFallback>
      </ErrorFallback>
    </div>
  )
}

export default PageContent
