import { get } from './clients/fetch'

import logger from '@utils/logger'

export const getConversations = async () => {
  return await get('/export-conversations').catch(error => {
    logger.error('getConversations()', { error })
    throw error
  })
}
