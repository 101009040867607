import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.module.css'

const Page = ({ children, className = '' }) => {
  return <div className={`${styles.page} ${className}`}>{children}</div>
}

Page.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Page
