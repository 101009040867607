import React from 'react'
import { Link } from 'react-router-dom'

import PageNav from '@components/PageNav'
import logo from '/messenger.png'

import styles from './styles.module.css'

const PageHeader = () => {
  return (
    <header id='page-header' className={styles.header}>
      <Link to='/'>
        <h1>
          Compassion
          <br />
          Messenger
        </h1>
        <div>
          <img alt='Compassion Messenger' src={logo} />
        </div>
      </Link>

      <PageNav />
    </header>
  )
}

export default PageHeader
